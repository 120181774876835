<template>
  <div>
    <v-row class="align-center justify-center mx-2">
      <LookupValueBtnToggle
        code
        resource="common/division"
        v-model="filterDivision"
        mandatory
        :disabled="loading"
      ></LookupValueBtnToggle>
      <v-spacer></v-spacer>
      <DateInput v-model="focus" class="mx-2" :disabled="loading" />

      <v-btn outlined @click="prev()" class="mx-2" :disabled="loading">
        <v-icon left>mdi-chevron-left</v-icon> vorherige Woche
      </v-btn>
      <v-btn outlined @click="next()" class="mx-2" :disabled="loading">
        nächste Woche<v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <v-card v-if="loading" class="mx-auto mt-8" width="400"
      ><v-progress-linear color="info" height="24" :value="progressValue">{{
        progressText
      }}</v-progress-linear></v-card
    >

    <v-simple-table v-else dense fixed-header height="90vh">
      <template v-for="dayItem in days">
        <thead :key="'thead-' + dayItem.day">
          <tr>
            <th></th>
            <th></th>
            <th
              v-for="schoolClass in schoolClassesFiltered"
              :key="'th-' + schoolClass.id"
              class="text-center"
            >
              {{ schoolClass.code }}
            </th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody :key="'tbody-' + dayItem.day" class="border">
          <tr
            v-for="periodItem in dayItem.periods"
            :key="'period-' + periodItem.period"
          >
            <th class="text-right">
              {{
                periodItem.period == "07:50" ? formatDayOfWeek(dayItem.day) : ""
              }}
              {{ periodItem.period == "08:45" ? formatDate(dayItem.day) : "" }}
            </th>
            <th class="text-right">{{ periodItem.period }}</th>
            <td
              v-for="schoolClassItem in periodItem.schoolClasses"
              :key="'td-' + schoolClassItem.schoolClass"
              class="border px-2"
            >
              <div class="d-flex align-center justify-space-between ma-0 pa-0">
                <SubjectShort
                  :value="schoolClassItem.items.map((el) => el.subject)"
                />
                &nbsp;
                <StatusShort
                  :value="schoolClassItem.items.map((el) => el.status)"
                />
                <RoomShort
                  :value="schoolClassItem.items.map((el) => el.rooms)"
                />
              </div>
            </td>
            <th class="border">{{ periodItem.period }}</th>
            <th>
              {{
                periodItem.period == "07:50" ? formatDayOfWeek(dayItem.day) : ""
              }}
              {{ periodItem.period == "08:45" ? formatDate(dayItem.day) : "" }}
            </th>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
import DateInput from "common/components/DateInput.vue";
import LookupValueBtnToggle from "common/components/LookupValueBtnToggle.vue";
import StatusShort from "./components/StatusShort.vue";
import SubjectShort from "./components/SubjectShort.vue";
import RoomShort from "./components/RoomShort.vue";
import {
  addDays,
  findMonday,
  formatDayOfWeek,
  formatDate,
  today,
} from "common/utils/date.js";

export default {
  name: "WeekOverviewSchoolclasses",
  components: {
    DateInput,
    LookupValueBtnToggle,
    StatusShort,
    SubjectShort,
    RoomShort,
  },
  props: ["search"],
  data() {
    return {
      abortController: null,
      days: [],
      progressCount: 0,
      focus: null,
      filterDivision: { code: 0 },
      loading: false,
      schoolClasses: [],
      periods: [],
      ready: false,
    };
  },
  watch: {
    focus() {
      this.update();
    },
    filterDivision() {
      localStorage.setItem(
        "weekoverviewDivision",
        JSON.stringify(this.filterDivision)
      );
      this.update();
    },
  },
  computed: {
    progressValue() {
      if (this.schoolClassesFiltered.length == 0) {
        return 0;
      }
      return (100 * this.progressCount) / this.schoolClassesFiltered.length;
    },
    progressText() {
      if (this.schoolClassesFiltered.length == 0) {
        return "laden...";
      }
      return `${this.progressCount} von ${this.schoolClassesFiltered.length} Klassen geladen...`;
    },
    schoolClassesFiltered() {
      if (!this.filterDivision) {
        return [];
      }
      return this.schoolClasses.filter(
        (el) => el.division.code == this.filterDivision.code
      );
    },
  },
  methods: {
    addDays,
    formatDayOfWeek,
    formatDate,
    addLesson(lesson, schoolClass) {
      const dayItem = this.days.find((el) => el.day == lesson.date);
      if (!dayItem) return;
      const periodItem = dayItem.periods.find(
        (el) => el.period == lesson.startTime
      );
      if (!periodItem) return;
      let schoolClassItem = periodItem.schoolClasses.find(
        (el) => el.schoolClass == schoolClass.code
      );
      if (!schoolClassItem) {
        return;
      }

      const item = {
        rooms: lesson.rooms.map((el) => el.code).join(", "),
        subject: lesson.course.subject.code,
        teachers: lesson.course.teachers.map((el) => el.code).join(", "),
        status: lesson.status,
      };
      schoolClassItem.items.push(item);
    },
    async fetchData() {
      this.schoolClasses = await this.apiList({
        resource: "schoolclass/schoolclass",
        signal: this.abortController.signal,
      });
      this.periods = await this.apiList({
        resource: "common/period",
        signal: this.abortController.signal,
      });
    },
    async fetchLessons() {
      for await (const schoolClass of this.schoolClassesFiltered) {
        // this.schoolClassesFiltered.forEach(async (schoolClass) => {
        this.progressCount += 1;
        const lessons =
          (await this.apiList({
            resource: "register/lesson",
            query: `startDate=${this.days[0].day}&endDate=${this.days[5].day}&schoolclass=${schoolClass.id}`,
            signal: this.abortController.signal,
          })) || [];
        for (const lesson of lessons) {
          this.addLesson(lesson, schoolClass);
        }
      }
    },
    async fetchLessonsAsync() {
      const promises = this.schoolClassesFiltered.map(async (schoolClass) => {
        const lessons =
          (await this.apiList({
            resource: "register/lesson",
            query: `startDate=${this.days[0].day}&endDate=${this.days[5].day}&schoolclass=${schoolClass.id}`,
            signal: this.abortController.signal,
          })) || [];
        this.progressCount += 1;
        for (const lesson of lessons) {
          this.addLesson(lesson, schoolClass);
        }
      });

      await Promise.all(promises);
    },
    async generateDays() {
      this.days = [];
      const monday = findMonday(this.focus);

      for (let i = 0; i < 6; i++) {
        const lessons = [];
        for await (const period of this.periods) {
          const schoolClassItems = [];
          for (const schoolClass of this.schoolClassesFiltered) {
            schoolClassItems.push({ schoolClass: schoolClass.code, items: [] });
          }
          lessons.push({
            period: period.startTime,
            schoolClasses: schoolClassItems,
          });
        }

        this.days.push({ day: addDays(monday, i), periods: lessons });
      }
    },
    prev() {
      this.focus = this.addDays(this.focus, -7);
    },
    next() {
      this.focus = this.addDays(this.focus, 7);
    },
    async update() {
      if (this.ready && !this.loading) {
        this.abortController = new AbortController();
        this.progressCount = 0;
        this.loading = true;
        await this.fetchData();
        await this.generateDays();
        await this.fetchLessonsAsync();
        this.abortController = null;
        this.loading = false;
      }
    },
  },
  async created() {
    this.focus = today();
    if (localStorage.getItem("weekoverviewDivision")) {
      this.filterDivision = JSON.parse(
        localStorage.getItem("weekoverviewDivision")
      );
    }
    this.ready = true;
    this.update();
  },
  destroyed() {
    if (this.abortController) {
      this.abortController.abort();
    }
  },
};
</script>
<style>
td.border,
th.border {
  border-left: 1px solid grey;
}
</style>
