<template>
  <div>
    <v-tooltip top v-if="hasTooltip">
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="text-caption text-right">*</span>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <span class="text-caption text-right">{{ display }}</span>
  </div>
</template>
<script>
export default {
  props: {
    value: [],
  },
  computed: {
    display() {
      if (this.value.length == 0) {
        return '';
      }
      if (this.value.length == 1) {
        return this.value[0].replace(', ', '/');
      }
      return '';
    },
    hasTooltip() {
      return this.value.length > 1;
    },
    tooltip() {
      return this.value.join(', ');
    },
  },
};
</script>
