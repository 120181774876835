<template>
  <v-tooltip top v-if="display != tooltip">
    <template v-slot:activator="{ on, attrs }">
      <span class="text-caption" v-bind="attrs" v-on="on" v-html="display">
      </span>
    </template>
    <span class="text-caption" v-html="tooltip"></span>
  </v-tooltip>
  <span v-else class="text-caption" v-html="tooltip"></span>
</template>
<script>
export default {
  props: {
    value: [],
  },
  computed: {
    display() {
      if (this.value.length == 0) {
        return "";
      }
      if (this.value.length == 1) {
        return this.value[0].replace(" ", "&thinsp;");
      }
      if (this.value.every((el) => el.includes("EF"))) {
        return "EF&thinsp;*";
      }
      if (this.value.every((el) => el.includes("SF"))) {
        return "SF&thinsp;*";
      }
      if (this.value.every((el) => el.includes("SP"))) {
        return "SP&thinsp;*";
      }
      if (this.value.every((el) => el.includes("PR"))) {
        return "PR&thinsp;*";
      }
      if (this.value.every((el) => el.includes("NE"))) {
        return "NExt&thinsp;*";
      }
      if (this.value.length == 2) {
        return this.value.join("/").replace(" ", "&thinsp;");
      }
      return "*";
    },
    tooltip() {
      if (this.value.length == 0) {
        return "";
      }
      if (this.value.length == 1) {
        return this.value[0].replace(" ", "&thinsp;");
      }
      if (this.value.every((el) => el.includes("EF"))) {
        return "EF&thinsp;*";
      }
      if (this.value.every((el) => el.includes("SF"))) {
        return "SF&thinsp;*";
      }
      if (this.value.every((el) => el.includes("SP"))) {
        return "SP&thinsp;*";
      }
      if (this.value.every((el) => el.includes("PR"))) {
        return "PR&thinsp;*";
      }
      if (this.value.every((el) => el.includes("NE"))) {
        return "NExt&thinsp;*";
      }

      return this.value.join("/").replace(" ", "&thinsp;");
    },
  },
};
</script>
